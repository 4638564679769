import React from 'react';
import PropTypes from 'prop-types';


function PractitionerCard({ practitioner, onClick }) {
  const handleClick = (data) => {
    onClick(data);
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="doctor-widget">
          <div className="doc-info-left">
            <div className="doctor-img">
              <a href="doctor-profile.html">
                <img
                  src={practitioner.photoUrl}
                  className="img-fluid"
                  alt={`${practitioner.firstName} ${practitioner.lastName}`}
                />
              </a>
            </div>
            <div className="doc-info-cont">
              <h4 className="doc-name">
                <a href="doctor-profile.html">{`${practitioner.salutation} ${practitioner.firstName} ${practitioner.lastName}`}</a>
              </h4>
              <p className="doc-speciality">
                {practitioner.qualificationSummary}
              </p>

              <div className="widget about-widget">
                <p>{practitioner.description}</p>
              </div>
            </div>
          </div>
          <div className="doc-info-right">

            <div className="clinic-booking">

              <a className="apt-btn" onClick={() => handleClick(practitioner)}>
                Book Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

PractitionerCard.propTypes = {
  practitioner: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};


export default PractitionerCard;
