
export default {
  api: {
    url: process.env.REACT_APP_SWIFTDOC_API_ENDPOINT,
    firebaseFuncURL: process.env.REACT_APP_FIREBASE_FUNC_URL
  },
  firebaseConfig: {
    apiKey: 'AIzaSyD5Rh-p04TlWtpirnpUY4BPd5fLi7f5670',
    authDomain: 'swiftdoc-dev-aa28c.firebaseapp.com',
    databaseURL: 'https://swiftdoc-dev-aa28c.firebaseio.com',
    projectId: 'swiftdoc-dev-aa28c',
    storageBucket: 'swiftdoc-dev-aa28c.appspot.com',
    messagingSenderId: '215335275879',
    appId: '1:215335275879:web:600d4fea47d8db39ed73c2',
    measurementId: 'G-YRZTKVKEHE'
  }
};
