import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import ProfileServices from 'src/services/api/profiles';
import PatientServices from 'src/services/api/patient';
import moment from 'moment';
import { useStateMachine } from 'little-state-machine';
import updateAction from 'src/components/updateAction';
import {
  useUser, AuthCheck, useAnalytics, SuspenseWithPerf
} from 'reactfire';
import { useForm } from 'react-hook-form';
import Login from 'src/components/auth/login';
import PaymentServices from 'src/services/api/payment';
import PatientForm from './patientform';


const titleOptions = [
  'Mr',
  'Mrs',
  'Miss',
  'Dr',
  'Ms',
  'Prof',
  'Rev',
  'Lady',
  'Sir',
  'Capt',
  'Major',
];

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2,
});

const Step4 = (props) => {
  const { action, state } = useStateMachine(updateAction);
  const location = useLocation();
  const user = useUser();
  const [profile, setProfile] = useState();
  const [patient, setPatient] = useState();
  const stripePromise = useRef();
  const anayltics = useAnalytics();


  const handleNext = () => {
    props.history.push('./result');
  };

  useEffect(() => {
    anayltics.logEvent('booking_step4');
  }, [anayltics]);

  // get the users profile
  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        const token = await user.getIdToken();

        ProfileServices.getByUid(token, user.uid).then((response) => {
          setProfile(response);
          if (response.patientId) {
          // we have a profile to retrieve, lets go get it and update the state
            PatientServices.get(response.patientId, token)
              .then((response1) => {
              // format the dob date first..
              // TODO: another shit place to do this there must be a better way
                try {
                  response.dateOfBirth = moment(response1.dateOfBirth).format(
                    'D-MM-YYYY'
                  );
                } catch {
                  console.log('Couldnt convert date to masked format  ');
                }
                // setPatient(response);
                setPatient(response1);

                console.log(response);
                console.log(response1);
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            setPatient({ data: false });
          }
        });
      }
    };
    fetchProfile();
  }, [user]);

  // get the stripe key and load stripe
  useEffect(() => {
    const getStripeKey = async () => {
    // get the stripe key
      if (user) {
        try {
          const token = await user.getIdToken();
          PaymentServices.getStripeKey(token).then((response) => {
            stripePromise.current = loadStripe(response.publishableKey);
          });
        } catch (err) {
          console.log(err);
        }
      }
    };
    getStripeKey();
  }, [user]);


  return (

    <AuthCheck fallback={<Login location={useLocation()} />}>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div className="card">
                <div className="card-body">
                  {}
                  {}
                  {patient && (
                    <Elements stripe={stripePromise.current}>
                      <PatientForm
                        patient={patient}
                        setPatient={setPatient}
                        afterSubmit={handleNext}
                      />
                    </Elements>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 theiaStickySidebar">
              {}
              <div className="card booking-card">
                <div className="card-header">
                  <h4 className="card-title">Booking Summary</h4>
                </div>
                <div className="card-body">
                  {}
                  <div className="booking-doc-info">
                    <a href="doctor-profile.html" className="booking-doc-img">
                      <img
                        src={state.data.practitioner.photoUrl}
                        alt={`${state.data.practitioner.firstName} ${state.data.practitioner.lastName}`}
                      />
                    </a>
                    <div className="booking-info">
                      <h4>
                        <a href="doctor-profile.html">{`${state.data.practitioner.salutation} ${state.data.practitioner.firstName} ${state.data.practitioner.lastName}`}</a>
                      </h4>
                      <p className="doc-speciality">
                        {state.data.practitioner.qualificationSummary}
                      </p>
                    </div>
                  </div>
                  {}
                  <div className="booking-summary">
                    <div className="booking-item-wrap">
                      <ul className="booking-date">
                        <li>
                          Date
                          {' '}
                          <span>
                            {moment(state.data.slot.startTime).format(
                              'D MMM YYYY'
                            )}
                          </span>
                        </li>
                        <li>
                          Time
                          {' '}
                          <span>
                            {moment(state.data.slot.startTime).format('h:mm A')}
                          </span>
                        </li>
                      </ul>
                      <ul className="booking-fee">
                        <li>
                          Consulting Fee
                          {' '}
                          <span>
                            {' '}
                            {formatter.format(
                              state.data.service.priceMicros / 100
                            )}
                          </span>
                        </li>
                      </ul>
                      <div className="booking-total">
                        <ul className="booking-total-list">
                          <li>
                            <span>Total</span>
                            <span className="total-cost">
                              {formatter.format(
                                state.data.service.priceMicros / 100
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {}
            </div>
          </div>
        </div>
      </div>
    </AuthCheck>

  );
};

export default withRouter(Step4);
