import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';
import 'firebase/performance';

const config = {
  api: {
    url: process.env.REACT_APP_SWIFTDOC_API_ENDPOINT,
    firebaseFuncURL: process.env.REACT_APP_FIREBASE_FUNC_URL
  },
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
  }
};


ReactDOM.unstable_createRoot(document.getElementById('root')).render(
  <StrictMode>
    <FirebaseAppProvider firebaseConfig={config.firebaseConfig}>
      <SuspenseWithPerf fallback="loading...">
        <App />
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  </StrictMode>
); // https://reactjs.org/docs/strict-mode.html

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
