// services/api/payment.js

import request from 'src/shared/lib/request';

function getStripeKey(authToken) {
  return request({
    url: '/payment/stripe/key',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}


const PaymentServices = {
  getStripeKey
};

export default PaymentServices;
