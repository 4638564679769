import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PractitionerServices from 'src/services/api/practitioner';
import { useStateMachine } from 'little-state-machine';
import updateAction from 'src/components/updateAction';
import { useAnalytics } from 'reactfire';
import PractitionerCard from './PractitionerCard';


const Step2 = (props) => {
  const { action, state } = useStateMachine(updateAction);

  const [practitioners, setPractitioners] = useState([]);

  const anayltics = useAnalytics();


  const onClick = (data) => {
    action({ practitioner: data });
    props.history.push('./step3');
  };
  useEffect(() => {
    anayltics.logEvent('booking_step2');
  }, [anayltics]);

  useEffect(() => {
    const fetchData = async () => {
      PractitionerServices.searchByService(state.data.service.id).then((response) => {
        setPractitioners(response);
      });
    };
    fetchData();
  }, [state.data.service.id]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8 col-xl-9">
            {}
            {practitioners && practitioners.map((practitioner) => (
              <PractitionerCard onClick={onClick} practitioner={practitioner} />

            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


export default withRouter(Step2);
