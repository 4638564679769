import React, { useState, useEffect } from 'react';
import './App.css';
import {
  preloadFirestoreDoc,
  useFirebaseApp,
  preloadUser,
  preloadAuth,
  preloadAnalytics,
  SuspenseWithPerf,
} from 'reactfire';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { DevTool } from 'little-state-machine-devtools';
import Layout from 'src/components/layout/Layout';
import Step1 from 'src/components/booking/step1';
import Step2 from 'src/components/booking/step2';
import Step3 from 'src/components/booking/step3';
import Step4 from 'src/components/booking/step4';
import Result from 'src/components/booking/result';
import Login from 'src/components/auth/login';
import Register from 'src/components/auth/register';
import Forgot from 'src/components/auth/forgot';
import { SuspenseSubject } from 'reactfire/useObservable/SuspenseSubject';
import logo from './logo.svg';

// create a data store for our multi step form wizard
createStore({
  data: {},
});

// Our components will lazy load the
// SDKs to decrease their bundle size.
// Since we know that, we can start
// fetching them now
const preloadSDKs = (firebaseApp) => Promise.all([preloadAnalytics(firebaseApp), preloadAuth(firebaseApp)]);

const preloadData = async (firebaseApp) => {
  const user = await preloadUser(firebaseApp);

  if (user) {
    preloadFirestoreDoc(
      (firestore) => firestore.doc('count/counter'),
      firebaseApp
    );
  }
};

function useQuery() {
  return new URLSearchParams(window.location.search);
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function App() {
  const firebaseApp = useFirebaseApp();
  const { analytics } = firebaseApp;

  // Kick off fetches for SDKs and data that
  // we know our components will eventually need.
  //
  // This is OPTIONAL but encouraged as part of the render-as-you-fetch pattern
  // https://reactjs.org/docs/concurrent-mode-suspense.html#approach-3-render-as-you-fetch-using-suspense
  preloadSDKs(firebaseApp).then(preloadData(firebaseApp));

  // logic to figure out if we are in an iframe or not
  // if we are in an iframe we need to render the header, no iframe no header :) good times
  const parentUrl = useQuery().get('url') ?? document.location.href;
  const isIFrame = window.location.href !== parentUrl;

  const renderGoogleAnalytics = () =>
  // if (process.env.NODE_ENV === 'development') return null;
    (
      <Route
        path="/"
        render={({ location }) => {
          if (typeof window.ga === 'function') {
            window.gtag('config', 'UA-101511875-1', {
              page_location: window.location.href,
              page_path: location.pathname,
              page_title: document.title,
            });
          }
          return null;
        }}
      />
    );

  // store main routes so we can conditionally render them without the main header
  const MyRoutes = () => (
    <Switch>
      <Route exact path="/">
        <Redirect to="/booking/step1" />
      </Route>
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/forgot" component={Forgot} />
      <StateMachineProvider>
        {process.env.NODE_ENV !== 'production' && <DevTool />}
        <Route exact path="/covid" component={Step1} />
        <Route exact path="/insurance" component={Step1} />
        <Route exact path="/booking/step1" component={Step1} />
        <Route exact path="/booking/step2" component={Step2} />
        <Route exact path="/booking/step3" component={Step3} />

        <Route exact path="/booking/step4" component={Step4} />
        <Route exact path="/booking/result" component={Result} />
      </StateMachineProvider>
    </Switch>
  );

  // add a main wrapper around the child routes
  const LayoutNoHeader = ({ children }) => (
    <div className="main-wrapper">{children}</div>
  );

  return (
    <Router>
      {renderGoogleAnalytics()}
      {isIFrame ? (
        <LayoutNoHeader>
          <MyRoutes />
        </LayoutNoHeader>
      ) : (
        <Layout>
          <MyRoutes />
        </Layout>
      )}
    </Router>
  );
}

export default App;
