import request from 'src/shared/lib/request';

function findTimeSlots(practitionerId, serviceId, startTime, endTime) {
  return request({
    url: '/time_slots',
    params: {
      practitionerId,
      serviceId,
      startTime,
      endTime
    },
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    method: 'GET'
  });
}

const SlotServices = {
  findTimeSlots // , update, delete, etc. ...
};

export default SlotServices;
