import React, { useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useFormContext } from 'react-hook-form';


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}


const LocationSearchInput = (props) => {
  const { userLocation, setUserLocation } = props;

  const loaded = React.useRef(false);
  const {
    register, setValue: setFormValue, errors
  } = useFormContext();

  const addressComponents = {
    subpremise: { type: 'short_name', camelCaseMap: 'subPremise', val: undefined },
    street_number: { type: 'short_name', camelCaseMap: 'streetNumber', val: undefined },
    route: { type: 'long_name', camelCaseMap: 'route', val: undefined },
    locality: { type: 'long_name', camelCaseMap: 'locality', val: undefined },
    administrative_area_level_1: { type: 'long_name', camelCaseMap: 'administrativeAreaLevel1', val: undefined },
    country: { type: 'short_name', camelCaseMap: 'country', val: undefined },
    postal_code: { type: 'short_name', camelCaseMap: 'postalCode', val: undefined }
  };
  React.useEffect(() => {
    // register({ name: 'locationField' }, { required: true });
  }, [register]);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Rh-p04TlWtpirnpUY4BPd5fLi7f5670&libraries=places&callback=initMap',
          document.querySelector('head'),
          'google-maps',
        );
      }

      loaded.current = true;
    }
  });


  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: { /* Define search scope here */ },
    debounce: 300,
    callbackName: 'initMap'
  });
  const ref = useRef();
  useOnclickOutside(ref, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        console.log(results);
        getLatLng(results[0]);

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        let myAddress;
        for (let i = 0; i < results[0].address_components.length; i++) {
          const addressType = results[0].address_components[i].types[0];
          if (addressComponents[addressType]) {
            const val = results[0].address_components[i][addressComponents[addressType].type];
            addressComponents[addressType].val = val;
            myAddress = {
              ...myAddress,
              [addressComponents[addressType].camelCaseMap]: val
            };
            console.log(`${addressType} ${val} ${addressComponents[addressType].camelCaseMap}`);
          }
        }
        console.log(results[0]);
        myAddress = {
          ...myAddress,
          placeId: results[0].place_id,
          formattedAddress: results[0].formatted_address
        };
        setFormValue('locationField', results[0].formatted_address);
        setUserLocation(myAddress);
      })
      .catch((error) => {
        console.log('😱 Error: ', error);
      });
  };

  const renderSuggestions = () => data.map((suggestion) => {
    const {
      id,
      structured_formatting: { main_text, secondary_text }
    } = suggestion;

    return (
      <li
        key={id}
        onClick={handleSelect(suggestion)}
        style={{ cursor: 'pointer' }}
      >
        <strong>{main_text}</strong>
        {' '}
        <small>{secondary_text}</small>
      </li>
    );
  });

  return (
    <div ref={ref}>
      <input
        name="locationField"
        // value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Start typing to select your address..."
        className="form-control"
        ref={register()}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default LocationSearchInput;
