import React, {
  useEffect, useState, useRef, createRef
} from 'react';

import { useUser, useAnalytics } from 'reactfire';
import { withRouter } from 'react-router-dom';
import SlotServices from 'src/services/api/slots';
import { useStateMachine } from 'little-state-machine';
import updateAction from 'src/components/updateAction';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayHeader from './DayHeader';
import TimeSlots from './TimeSlots';


const Step3 = (props) => {
  const { action, state } = useStateMachine(updateAction);

  const [slots, setSlots] = useState([]);
  const inputRef = useRef([]);

  const anayltics = useAnalytics();


  useEffect(() => {
    anayltics.logEvent('booking_step3');
  }, [anayltics]);


  // set startTime and endtime
  const now = moment();
  const [startTime, setStartTime] = useState(now.startOf('day'));
  const [endTime, setEndTime] = useState(startTime.clone().add(7, 'days'));
  const [selectedSlotId, setSelectedSlotId] = useState({
    day: null,
    index: null
  });
  const [isSelected, setIsSelected] = useState(false);


  // setup days header
  const [daysHeader, setDaysHeader] = useState([]);
  const user = useUser();


  const handleSlotClick = (e, day, i) => {
    const items = { ...slots };
    // unset the previously selected slot
    if (selectedSlotId.day != null) {
      const item1 = {
        ...items[selectedSlotId.day][selectedSlotId.index],
        isSelected: false
      };
      items[selectedSlotId.day][selectedSlotId.index] = item1;
      setSlots(items);
    }
    console.log(e);
    console.log(i);

    const item = {
      ...items[day][i],
      isSelected: true
    };
    // keep track of the selected slot
    setSelectedSlotId({ day, index: i });
    // push the slot onto the state machine
    // action({ slot: item });
    items[day][i] = item;
    // 5. Set the state to our new copy
    setSlots(items);
    action({ slot: item });
    setIsSelected(true);
  };

  const handleProceedToPayClick = () => {
    props.history.push('./step4');
  };


  // Add 7 Days to start and end times
  const onClickRight = () => {
    setStartTime(startTime.clone().add(7, 'days'));
    setEndTime(endTime.clone().add(7, 'days'));
  };
  // subtract 7 days from start and end times
  const onClickLeft = () => {
    setStartTime(startTime.clone().subtract(7, 'days'));
    setEndTime(endTime.clone().subtract(7, 'days'));
  };

  // Get an array of days between two dates
  const enumerateDaysBetweenDates = (startDate, stopDate) => {
    const dateArray = [];
    let currentDate = moment(startDate);

    while (currentDate.isBefore(stopDate)) {
      dateArray.push(moment(currentDate));
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  };

  useEffect(() => {
    const days = enumerateDaysBetweenDates(startTime, endTime);
    setDaysHeader(days);
  }, [startTime, endTime]);

  useEffect(() => {
    let mounted = true;

    const fetchTimeSlots = async () => {
      // get slots from API

      const mySlots = await SlotServices.findTimeSlots(
        state.data.practitioner.id,
        state.data.service.id,
        startTime.clone().utc().format(),
        endTime.clone().utc().format()
      )
        .catch((err) => {
          console.log(err);
        });


      // re-key slots to be indexed by day and convert to local time objects
      const newTimes = mySlots.slice(0)
        .sort((a, b) => a.startTime - b.endTime)
        .reduce((obj, item) => {
          const sTime = moment.utc(item.startTime).local();
          const eTime = moment.utc(item.endTime).local();
          const day = sTime.format('YYYY-MM-DD');
          const isSelected = false;
          const newItem = {
            ...item,
            startTime: sTime,
            endTime: eTime,
            isSelected,

          };


          if (!obj[day]) {
            obj[day] = [newItem];
          } else {
            obj[day] = [...obj[day], newItem];
          }
          return obj;
        }, {});
      if (mounted) {
        setSlots(newTimes);
      }
    };

    fetchTimeSlots();
    return () => {
      mounted = false;
    };
  }, [endTime, startTime, state.data.practitioner.id, state.data.service.id, user]);

  return (
    <div className="content">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="booking-doc-info">
                  <a href="doctor-profile.html" className="booking-doc-img">
                    <img
                      src={state.data.practitioner.photoUrl}
                      className="img-fluid"
                      alt={`${state.data.practitioner.firstName} ${state.data.practitioner.lastName}`}
                    />
                  </a>
                  <div className="booking-info">
                    <h4>
                      <a href="doctor-profile.html">{`${state.data.practitioner.salutation} ${state.data.practitioner.firstName} ${state.data.practitioner.lastName}`}</a>
                    </h4>
                    <div className="widget about-widget">
                      <p>{state.data.practitioner.description}</p>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 col-md-6">
                <h4 className="mb-1">
                  {moment().format('D MMMM YYYY')}
                </h4>
                <p className="text-muted">
                  {moment().format('dddd')}
                </p>
              </div>
              <div className="col-12 col-sm-8 col-md-6 text-sm-right">
                <div className="submit-section proceed-btn text-right">
                  <button type="button" disabled={!isSelected} onClick={handleProceedToPayClick} className="btn btn-primary submit-btn">
                    Proceed
                  </button>
                </div>

              </div>
            </div>
            {}
            <div className="card booking-schedule schedule-widget">
              {}
              <div className="schedule-header">
                <div className="row">
                  <div className="col-md-12">
                    <div className="day-slot">
                      <ul>
                        <li className="left-arrow">
                          <a onClick={onClickLeft}>
                            <i className="fa fa-chevron-left" />
                          </a>
                        </li>
                        {daysHeader && daysHeader.map((day) => (
                          <DayHeader day={day} />

                        ))}

                        <li className="right-arrow">
                          <a onClick={onClickRight}>
                            <i className="fa fa-chevron-right" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    {}
                  </div>
                </div>
              </div>
              {}
              {}
              <div className="schedule-cont">
                <div className="row">
                  <div className="col-md-12">
                    {}
                    <div className="time-slot">
                      <ul className="clearfix">

                        {daysHeader && daysHeader.map((day) => (
                          <li>
                            {slots[day.format('YYYY-MM-DD').toString()] ? (
                              slots[day.format('YYYY-MM-DD').toString()].map((slot, i) => (
                                <div onClick={(e) => handleSlotClick(e, day.format('YYYY-MM-DD').toString(), i)} slot={slot} className={slot.isSelected ? 'timing selected' : 'timing'}>
                                  <span>
                                    {slot.startTime.format('h:mm').toString()}
                                  </span>
                                  {' '}
                                  <span>{slot.startTime.format('A').toString()}</span>
                                </div>
                              ))
                            ) : (
                              <a className="timing" href="#">
                                <span>-</span>
                              </a>
                            )}
                          </li>
                        ))}


                      </ul>
                    </div>
                    {}
                  </div>
                </div>
              </div>
              {}
            </div>
            {}
            {}
            <div className="submit-section proceed-btn text-right">
              <button type="button" disabled={!isSelected} onClick={handleProceedToPayClick} className="btn btn-primary submit-btn">
                Proceed
              </button>
            </div>
            {}
          </div>
        </div>
      </div>
    </div>
  );
};

Step3.propTypes = {
  props: PropTypes.object.isRequired
};


export default withRouter(Step3);
