// services/api/services.js
import request from 'src/shared/lib/request';
import { v4 } from 'uuid';

function get(id, authToken) {
  return request({
    url: `/patient/${id}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}

// create a patient booking
function create(authToken, data) {
  const id = v4();
  return request({
    url: `/patient/${data.appointmentPatientId}/booking`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },

    data: {
      ...data,
      id
    }
  });
}

const BookingServices = {
  get,
  create // , update, delete, etc. ...
};

export default BookingServices;
