// services/api/practitioner.js

/*
 * location details how to set them
private CountryCode countryCode; // AU for australia
private String administrativeArea; //State
private String subAdministrativeArea; //not used Australia
private String locality;  //city
private String postalCode; //postcode

*/
import { v4 } from 'uuid';
import request from 'src/shared/lib/request';

function searchByService(serviceId) {
  return request({
    url: '/practitioner/search',
    params: { service_id: serviceId },
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      // Authorization: `Bearer ${authToken}`

    },
    method: 'GET',

  });
}

function listing(authToken) {
  return request({
    url: '/practitioner/',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}
// Get practioner by practitioner ID
function getById(authToken, practitionerId) {
  return request({
    url: `/practitioner/${practitionerId}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}


// Create or Update a practitioner
function createOrUpdate(authToken, values, practitionerId, version, isUpdate) {
  console.log(values);
  // if we have an id we have a profile to update
  let url = '/practitioner';
  let method = 'POST';
  if (isUpdate) {
    url = `/practitioner/${practitionerId}`;
    method = 'PUT';
  }

  const data = {
    // values that change between post and put
    version: version || 1,
    id: practitionerId || (v4()),

    // normal data
    salutation: values.salutation,
    ranking: values.ranking,
    description: values.description,
    qualificationSummary: values.qualificationSummary,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    location: {
      administrativeArea: values.state,
      countryCode: 'AU',
      geoPoint: {
        latitute: 0,
        longitue: 0
      },
      line1: values.address1,
      line2: values.address2,
      line3: 'string',
      locality: values.city,
      postalCode: values.postalCode,
      subAdministrativeArea: 'string'
    },
    photoUrl: values.photoUrl,
    smsReminderEnable: true,
    timezone: values.timezone,
    userId: values.userId

  };

  return request({
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    data
  });
}


// update a practitioner
function update(values, authToken) {
  return request({
    url: `/practitioner/${values.id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },

    data: {
      // TODO: [SLWIDGET-19] Make the salutation field dynamic
      salutation: 'Dr',
      description: values.description,
      qualificationSummary: values.qualificationSummary,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      location: {
        administrativeArea: values.state,
        countryCode: 'AU',
        geoPoint: {
          latitute: 0,
          longitue: 0
        },
        line1: values.address1,
        line2: values.address2,
        line3: 'string',
        locality: values.city,
        postalCode: values.postalCode,
        subAdministrativeArea: 'string'
      },
      photoUrl: values.photoUrl,
      id: values.id,
      smsReminderEnable: true,
      version: values.version,
      timezone: values.timezone
    }
  });
}


// create a practitioner
function create(values, authToken) {
  return request({
    url: '/practitioner',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    data: {
      // TODO: [SLWIDGET-19] Make the salutation field dynamic
      userId: values.uid,
      salutation: 'Dr',
      qualificationSummary: values.qualificationSummary,
      description: values.description,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      location: {
        administrativeArea: values.state,
        countryCode: 'AU',
        line1: values.address1,
        line2: values.address2,
        line3: 'string',
        locality: values.city,
        postalCode: values.postalCode,
        subAdministrativeArea: 'string'
      },
      photoUrl: values.photoUrl,
      id: v4(),
      smsReminderEnable: true,
      timezone: values.timezone
    }

  });
}


// delete a practitioner
function deletePractitioner(authToken, id) {
  console.log(id);
  return request({
    url: `/practitioner/${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
  });
}

const PractitionerServices = {
  createOrUpdate,
  searchByService,
  getById,
  listing,
  deletePractitioner,
  update,
  create // , update, delete, etc. ...
};

export default PractitionerServices;
