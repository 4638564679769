import React from 'react';

const LogoIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="262px"
    height="58px"
    viewBox="53.53 97.151 1702 382"
    enableBackground="new 53.53 97.151 1702 382"
    xmlSpace="preserve"
  >
    <defs />
    <g>
      <defs>
        <rect id="SVGID_3_" width="1734.824" height="593.715" />
      </defs>
      <clipPath id="SVGID_4_">
        <use xlinkHref="#SVGID_3_" overflow="visible" />
      </clipPath>
    </g>
    <path
      fill="#003F62"
      d="M1690.961,203.341c-19.981,0-36.236-16.252-36.236-36.227c0-19.974,16.255-36.224,36.236-36.224
	c19.974,0,36.221,16.25,36.221,36.224C1727.182,187.089,1710.935,203.341,1690.961,203.341z M1690.961,136.91
	c-16.658,0-30.213,13.548-30.213,30.205c0,16.658,13.555,30.206,30.213,30.206c16.642,0,30.188-13.548,30.188-30.206
	C1721.15,150.458,1707.603,136.91,1690.961,136.91z"
    />
    <path
      fill="#003F62"
      d="M1706.571,161.587c0,5.736-2.499,9.349-7.496,10.837l9.089,12.636h-7.459l-8.307-11.688h-7.713v11.688
	h-5.865v-34.767h12.938c5.299,0,9.098,0.894,11.38,2.692C1705.428,154.777,1706.571,157.641,1706.571,161.587z M1698.765,166.509
	c1.191-1.026,1.796-2.674,1.796-4.951c0-2.269-0.611-3.823-1.848-4.669c-1.227-0.85-3.417-1.265-6.561-1.265h-7.468v12.433h7.317
	C1695.32,168.057,1697.565,167.539,1698.765,166.509z"
    />
    <path
      fill="#00425C"
      d="M154.27,291.74c11.223,0,18.27-3.132,18.27-8.613c0-4.437-4.959-7.569-13.311-8.874l-25.578-4.437
	c-21.663-3.654-37.062-15.922-37.062-38.106c0-26.622,22.707-42.283,56.376-42.283c30.015,0,57.159,10.179,58.986,43.587h-37.323
	c-0.783-9.396-9.918-13.572-21.663-13.572c-10.962,0-15.921,3.915-15.921,8.613c0,4.438,3.132,8.353,13.311,10.179l23.49,3.915
	c23.49,4.438,39.411,15.922,39.411,38.367c0,29.232-26.1,42.282-59.247,42.282c-32.625,0-59.508-13.311-62.379-46.979l37.584,0.261
	C131.041,287.042,140.958,291.74,154.27,291.74z"
    />
    <path
      fill="#00425C"
      d="M427.014,192.82l-41.76,126.586h-37.585l-22.707-73.603l-22.968,73.603h-37.584L222.65,192.82h44.37
	l17.748,72.819l23.229-72.819h33.669l23.229,72.298l17.748-72.298H427.014z"
    />
    <path
      fill="#00425C"
      d="M442.368,149.233c0-13.833,10.962-24.012,24.795-24.012c13.572,0,24.795,10.179,24.795,24.012
	c0,13.572-11.223,23.751-24.795,23.751C453.33,172.984,442.368,162.805,442.368,149.233z M488.565,192.82v126.586h-42.282V192.82
	H488.565z"
    />
    <path
      fill="#00425C"
      d="M726.635,278.69c-7.569,2.87-14.354,5.22-22.446,5.22c-12.267,0-19.836-6.003-19.836-19.054v-35.757h43.065
	V192.82h-43.065v-43.065h-42.282v43.065h-14.354h-10.441h-34.19v-7.308c0-12.789,8.353-20.88,21.925-20.88
	c5.48,0,13.05,1.827,19.313,3.915l5.742-35.235c-13.05-3.393-21.924-4.959-32.103-4.959c-32.364,0-57.16,20.358-57.16,57.159v7.308
	h-31.059v36.279h31.059v90.307h42.282v-90.307h34.19h10.441h14.354v37.063c0,34.191,20.88,56.637,55.332,56.637
	c7.309,0,20.881-0.782,33.931-6.264L726.635,278.69z"
    />
    <path
      fill="#00425C"
      d="M885.841,134.095v185.311h-42.282v-6.264c-9.396,6.003-20.619,9.656-34.713,9.656
	c-34.191,0-62.901-28.71-62.901-66.555c0-37.585,28.71-66.295,62.901-66.295c14.094,0,25.317,3.654,34.713,9.918v-65.772H885.841z
	 M843.559,276.08v-39.15c-8.091-8.874-16.965-11.223-26.1-11.223c-17.227,0-30.016,13.311-30.016,30.537
	c0,17.748,12.789,31.059,30.016,31.059C826.594,287.302,835.468,284.954,843.559,276.08z"
    />
    <path
      fill="#00425C"
      d="M1043.53,255.982c0,38.629-29.493,66.816-69.426,66.816c-40.194,0-69.688-28.188-69.688-66.816
	c0-38.105,29.493-66.555,69.688-66.555C1014.037,189.427,1043.53,217.876,1043.53,255.982z M1003.858,256.244
	c0-17.487-12.006-31.32-29.754-31.32c-18.01,0-30.016,13.833-30.016,31.32c0,18.009,12.006,31.32,30.016,31.32
	C991.852,287.564,1003.858,274.252,1003.858,256.244z"
    />
    <path
      fill="#00425C"
      d="M1156.368,267.466h39.933c-3.132,32.625-30.015,55.332-64.989,55.332c-39.672,0-69.165-28.188-69.165-66.816
	c0-38.367,29.493-66.555,69.165-66.555c34.975,0,61.857,22.708,64.989,55.333h-39.933c-2.088-11.484-12.007-19.575-24.796-19.575
	c-18.27,0-28.971,13.572-28.971,30.798c0,17.227,10.701,31.06,28.971,31.06C1144.361,287.042,1154.28,278.951,1156.368,267.466z"
    />
    <g>
      <defs>
        <path
          id="SVGID_2_"
          d="M1283.801,328.443v-47.888c0-2.824,2.279-5.106,5.101-5.106h17.675h32.992h2.229v-52.892
			c0-2.825,2.285-5.11,5.104-5.11h45.652c2.821,0,5.106,2.285,5.106,5.11v52.892h2.232h32.997h17.665
			c2.824,0,5.106,2.282,5.106,5.106v47.888c0,2.816-2.282,5.102-5.106,5.102h-17.665h-32.997h-2.232v52.899
			c0,2.815-2.285,5.101-5.106,5.101h-45.652c-2.819,0-5.104-2.285-5.104-5.101v-52.899h-2.229h-32.992h-17.675
			C1286.081,333.544,1283.801,331.259,1283.801,328.443 M1262.646,188.209c-6.34,0-11.479,5.137-11.479,11.476v38.538v132.554
			v43.084c0,6.339,5.14,11.479,11.479,11.479h214.175c6.335,0,11.469-5.14,11.469-11.479v-43.084V238.223v-38.538
			c0-6.339-5.134-11.476-11.469-11.476H1262.646z"
        />
      </defs>
      <clipPath id="SVGID_5_">
        <use xlinkHref="#SVGID_2_" overflow="visible" />
      </clipPath>

      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="663.5698"
        y1="329.8071"
        x2="668.466"
        y2="329.8071"
        gradientTransform="matrix(0 48.5762 48.5762 0 -14651.0313 -32045.1035)"
      >
        <stop offset="0" style={{ stopColor: '#0089CF' }} />
        <stop offset="1" style={{ stopColor: '#003F62' }} />
      </linearGradient>
      <rect
        x="1251.167"
        y="188.209"
        clipPath="url(#SVGID_5_)"
        fill="url(#SVGID_6_)"
        width="237.123"
        height="237.13"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_1_"
          d="M1599.671,209.534l-78.386,52.672l-0.076,0.08c-4.705,3.278-13.31,9.23-13.31,17.891v17.756v13.227v17.34
			c0,8.645,7.237,13.816,12.071,17.401l79.7,53.551c1.842,1.244,3.979,1.868,6.125,1.868c1.765,0,3.542-0.42,5.157-1.285
			c3.582-1.908,5.814-5.636,5.814-9.691V218.651c0-4.059-2.232-7.781-5.814-9.688c-1.615-0.868-3.393-1.294-5.167-1.294
			C1603.645,207.669,1601.508,208.296,1599.671,209.534"
        />
      </defs>
      <clipPath id="SVGID_7_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>

      <linearGradient
        id="SVGID_8_"
        gradientUnits="userSpaceOnUse"
        x1="663.5713"
        y1="333.0024"
        x2="668.4658"
        y2="333.0024"
        gradientTransform="matrix(0 48.5762 48.5762 0 -14613.6875 -32045.1035)"
      >
        <stop offset="0" style={{ stopColor: '#0089CF' }} />
        <stop offset="1" style={{ stopColor: '#003F62' }} />
      </linearGradient>
      <rect
        x="1507.9"
        y="207.669"
        clipPath="url(#SVGID_7_)"
        fill="url(#SVGID_8_)"
        width="108.868"
        height="193.65"
      />
    </g>
    <path
      fill="#0A89CA"
      d="M183.679,376.657l-38.934,86.176h-10.043l12.027-26.658l-29.634-59.518h10.167l24.055,49.474l22.318-49.474
	H183.679z"
    />
    <path
      fill="#0A89CA"
      d="M250.019,405.423c0,17.359-13.392,30.255-30.502,30.255c-17.359,0-30.626-12.896-30.626-30.255
	c0-17.11,13.268-30.254,30.626-30.254C236.627,375.169,250.019,388.313,250.019,405.423z M240.471,405.423
	c0-11.903-8.927-21.574-20.955-21.574c-12.151,0-21.203,9.671-21.203,21.574c0,12.151,9.051,21.575,21.203,21.575
	C231.543,426.999,240.471,417.575,240.471,405.423z"
    />
    <path
      fill="#0A89CA"
      d="M318.59,376.657v57.533h-9.423v-6.571c-5.332,5.083-12.399,8.06-20.583,8.06
	c-14.631,0-24.675-10.044-24.675-26.287v-32.734h9.423v32.238c0,11.655,6.82,18.104,16.987,18.104
	c6.819,0,13.887-3.596,18.847-10.168v-40.174H318.59z"
    />
    <path
      fill="#0A89CA"
      d="M369.927,375.293v8.928c-9.423,0.248-17.111,4.587-22.195,12.522v37.446h-9.423v-57.533h9.423v9.424
	C353.064,379.261,360.752,375.293,369.927,375.293z"
    />
    <path
      fill="#0A89CA"
      d="M472.844,405.423c0,17.359-13.392,30.255-30.502,30.255c-17.359,0-30.626-12.896-30.626-30.255
	c0-17.11,13.268-30.254,30.626-30.254C459.453,375.169,472.844,388.313,472.844,405.423z M463.296,405.423
	c0-11.903-8.927-21.574-20.955-21.574c-12.151,0-21.203,9.671-21.203,21.574c0,12.151,9.051,21.575,21.203,21.575
	C454.369,426.999,463.296,417.575,463.296,405.423z"
    />
    <path
      fill="#0A89CA"
      d="M542.903,401.208v32.982h-9.423v-32.486c0-11.655-6.819-17.854-16.987-17.854
	c-6.82,0-13.887,3.472-18.847,9.919v40.422h-9.423v-57.533h9.423v6.448c5.332-5.084,12.399-7.936,20.583-7.936
	C532.859,375.169,542.903,384.964,542.903,401.208z"
    />
    <path fill="#0A89CA" d="M569.689,346.155v88.035h-9.423v-88.035H569.689z" />
    <path
      fill="#0A89CA"
      d="M589.282,355.083c0-3.596,2.728-6.323,6.324-6.323s6.447,2.728,6.447,6.323c0,3.472-2.852,6.199-6.447,6.199
	S589.282,358.554,589.282,355.083z M600.441,376.657v57.533h-9.423v-57.533H600.441z"
    />
    <path
      fill="#0A89CA"
      d="M676.325,401.208v32.982h-9.423v-32.486c0-11.655-6.82-17.854-16.987-17.854
	c-6.82,0-13.887,3.472-18.847,9.919v40.422h-9.423v-57.533h9.423v6.448c5.332-5.084,12.399-7.936,20.583-7.936
	C666.282,375.169,676.325,384.964,676.325,401.208z"
    />
    <path
      fill="#0A89CA"
      d="M748.122,408.276l-0.372-0.124v0.124h-47.861c1.116,10.663,8.431,18.971,20.458,18.971
	c7.316,0,12.896-2.604,15.375-7.439h10.54c-3.596,9.919-13.515,15.871-25.915,15.871c-17.731,0-30.006-13.268-30.006-30.255
	c0-16.986,12.151-30.254,29.51-30.254c16.739,0,28.395,12.151,28.519,29.51C748.37,405.919,748.37,407.036,748.122,408.276z
	 M700.013,401.58h38.686c-0.992-11.159-7.936-18.227-18.847-18.227C708.444,383.353,701.377,391.413,700.013,401.58z"
    />
    <path
      fill="#0A89CA"
      d="M854.139,346.155v88.035h-9.422v-5.951c-5.084,4.711-12.028,7.439-20.211,7.439
	c-16.491,0-29.635-13.516-29.635-30.255c0-16.862,13.144-30.254,29.635-30.254c8.184,0,15.127,2.728,20.211,7.439v-36.454H854.139z
	 M844.717,417.203v-23.559c-4.712-6.943-11.409-9.795-18.972-9.795c-12.151,0-21.45,9.671-21.45,21.574s9.299,21.575,21.45,21.575
	C833.309,426.999,840.005,424.147,844.717,417.203z"
    />
    <path
      fill="#0A89CA"
      d="M931.641,405.423c0,17.359-13.391,30.255-30.502,30.255c-17.359,0-30.627-12.896-30.627-30.255
	c0-17.11,13.268-30.254,30.627-30.254C918.25,375.169,931.641,388.313,931.641,405.423z M922.094,405.423
	c0-11.903-8.928-21.574-20.955-21.574c-12.15,0-21.203,9.671-21.203,21.574c0,12.151,9.053,21.575,21.203,21.575
	C913.167,426.999,922.094,417.575,922.094,405.423z"
    />
    <path
      fill="#0A89CA"
      d="M991.78,414.599h9.797c-2.232,12.523-13.268,21.079-27.154,21.079c-17.484,0-30.131-13.268-30.131-30.255
	c0-16.986,12.646-30.254,30.131-30.254c13.887,0,24.922,8.556,27.154,21.203h-9.797c-1.734-7.564-8.432-12.523-17.357-12.523
	c-12.523,0-20.707,9.671-20.707,21.574c0,12.027,8.184,21.575,20.707,21.575C983.348,426.999,990.045,422.163,991.78,414.599z"
    />
    <path
      fill="#0A89CA"
      d="M1056.139,433.57c-4.713,1.612-8.68,2.108-12.152,2.108c-14.135,0-20.955-7.812-20.955-22.815v-28.146
	h-15.002v-8.06h15.002v-21.202h9.424v21.202h21.699v8.06h-21.699v28.146c0,9.92,4.217,14.012,12.4,14.012
	c3.223,0,6.818-0.868,9.918-1.984L1056.139,433.57z"
    />
    <path
      fill="#0A89CA"
      d="M1124.709,405.423c0,17.359-13.391,30.255-30.502,30.255c-17.359,0-30.627-12.896-30.627-30.255
	c0-17.11,13.268-30.254,30.627-30.254C1111.319,375.169,1124.709,388.313,1124.709,405.423z M1115.163,405.423
	c0-11.903-8.928-21.574-20.955-21.574c-12.15,0-21.203,9.671-21.203,21.574c0,12.151,9.053,21.575,21.203,21.575
	C1106.235,426.999,1115.163,417.575,1115.163,405.423z"
    />
    <path
      fill="#0A89CA"
      d="M1171.706,375.293v8.928c-9.422,0.248-17.109,4.587-22.193,12.522v37.446h-9.424v-57.533h9.424v9.424
	C1154.844,379.261,1162.532,375.293,1171.706,375.293z"
    />
  </svg>
);

export default LogoIcon;
