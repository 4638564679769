import React from 'react';
import PropTypes from 'prop-types';


const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2
});


function ServiceCard({ service, onClick }) {
  const handleClick = (data) => {
    onClick(data);
  };

  const formattedPrice = () => {
    if (service.priceMicros > 0) {
      return formatter.format(service.priceMicros / 100);
    }
    return 'Bulk Billed';
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="doctor-widget">
          <div className="doc-info-left">
            <div className="doctor-img">
              <a href="doctor-profile.html">
                <img
                  src={service.photoUrl}
                  className="img-fluid"
                  alt="Service"
                />
              </a>
            </div>
            <div className="doc-info-cont">
              <h4 className="doc-name">
                <a href="doctor-profile.html">{service.name}</a>
              </h4>
              <p className="doc-speciality">
                {service.description}
              </p>
            </div>
          </div>
          <div className="doc-info-right">
            <div className="clini-infos">
              <ul>
                <li>
                  <i className="far fa-clock" />
                  {' '}
                  {Math.floor(service.durationSeconds / 60)}
                  mins
                </li>


                <li>
                  <i className="far fa-money-bill-alt" />
                  {' '}
                  {formattedPrice()}
                  {' '}
                </li>
              </ul>
            </div>
            <div className="clinic-booking">
              <a className="apt-btn" onClick={() => handleClick(service)}>
                Book Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};


export default ServiceCard;
