import React from 'react';
import { useUser, useAuth } from 'reactfire';
import md5 from 'md5';

const UserMenu = () => {
  const user = useUser();
  const defaultAuth = useAuth();
  // handle the form submit
  const onLogoutClick = async () => {
    try {
      // wait to see if login was successful (we don't care about the return
      // value here)
      defaultAuth
        .signOut()
        .then(() => {
          console.log('signed out');
        });
    } catch (err) {
      console.error(err);
    }
  };

  const avatarURL = `https://www.gravatar.com/avatar/${md5(user.email)}?d=mp`;
  return (
    <ul className="nav header-navbar-rht">

      <li className="nav-item dropdown has-arrow logged-item">
        <a
          href="#"
          className="dropdown-toggle nav-link"
          data-toggle="dropdown"
        >
          <span className="user-img">
            <img
              className="rounded-circle"
              src={avatarURL}
              width={31}
              alt={user.email}
            />
          </span>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="user-header">
            <div className="avatar avatar-sm">
              <img
                src={avatarURL}
                alt="User"
                className="avatar-img rounded-circle"
              />
            </div>
            <div className="user-text">
              <h6>{user.email}</h6>
              <p className="text-muted mb-0">Patient</p>
            </div>
          </div>
          <a className="dropdown-item" onClick={onLogoutClick}>
            Logout
          </a>
        </div>
      </li>
      {}
    </ul>
  );
};

export default UserMenu;
