import React, { useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import PractitionerServices from 'src/services/api/services';

import { useStateMachine } from 'little-state-machine';
import updateAction from 'src/components/updateAction';
import { useAnalytics } from 'reactfire';

import ServiceCard from './ServiceCard';


const Step1 = (props) => {
  const { action, state } = useStateMachine(updateAction);
  const location = useLocation();

  const anayltics = useAnalytics();


  const [services, setServices] = useState([]);

  const onClick = (data) => {
    action({ service: data });
    props.history.push('/booking/step2');
  };

  useEffect(() => {
    anayltics.logEvent('booking_begin');
  }, [anayltics]);

  useEffect(() => {
    const fetchData = async () => {
      PractitionerServices.ServiceListing().then((response) => {
        if (location.pathname === '/covid') {
          const res = [response.find(({ id }) => id === '969d9c94-fd48-4686-82ac-490d33f6a41c')];
          setServices(res);
        } else if (location.pathname === '/insurance') {
          const filter = ['4a4490f6-9891-4c33-9471-b7e91f2b9ecb', 'd9b9cadb-7c24-4a44-a68f-60c153670c81', 'e643a44c-3143-4d46-b3dd-e05d0f1ae5e2'];
          const results = response.filter((el) => (filter.indexOf(el.id) >= 0));

          setServices(results);
        } else {
          setServices(response);
        }
      });
    };
    fetchData();
  }, [location.pathname]);


  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8 col-xl-9">
            {}
            {services && services.map((service) => (
              <ServiceCard onClick={onClick} service={service} />

            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


export default withRouter(Step1);
