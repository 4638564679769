import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'reactfire';
import { Link } from 'react-router-dom';

function Forgot() {
  const defaultAuth = useAuth();

  const {
    register, handleSubmit, watch, errors
  } = useForm();

  // handle the form submit
  const onSubmit = async (values) => {
    try {
      // wait to see if login was successful (we don't care about the return
      // value here)
      defaultAuth
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => {
          console.log('signed in');
        });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {}
            <div className="account-content">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12 col-lg-6 login-right">
                  <div className="login-header">
                    <h3>Forgot Password?</h3>
                    <p className="small text-muted">
                      Enter your email to get a password reset link
                    </p>
                  </div>
                  {}
                  <form action="login.html">
                    <div className="form-group form-focus">
                      <input type="email" className="form-control floating" />
                      <label className="focus-label">Email</label>
                    </div>
                    <div className="text-right">
                      <Link className="forgot-link" to="/login">
                        Remembered your password?
                      </Link>
                    </div>
                    <button
                      className="btn btn-primary btn-block btn-lg login-btn"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </form>
                  {}
                </div>
              </div>
            </div>
            {}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
