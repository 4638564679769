import request from 'src/shared/lib/request';
import { v4 } from 'uuid';

// get all the services grouped together
// TODO: use a bettter naming strategy
function ServiceGroupListing() {
  return request({
    url: '/servicegroup/listing',
    method: 'GET'
  });
}

// Get service by ID
function getById(authToken, serviceId) {
  return request({
    url: `/service/${serviceId}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}

// Get all the services not grouped
function ServiceListing() {
  return request({
    url: '/service/',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json'
    },
    method: 'GET'
  });
}

// Get all the services not grouped
function Create(authToken, values) {
  return request({
    url: '/service',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`

    },
    method: 'POST',
    data: {
      chargeType: values.chargeType,
      description: values.description,
      durationSeconds: values.durationSeconds,
      id: v4(),
      interactionType: values.interactionType,
      name: values.name,
      photoUrl: values.photoUrl,
      priceMicros: values.priceMicros,
      serviceType: values.serviceType,
      smsReminderEnable: values.smsReminderEnable,
      version: 1

    }
  });
}

const Services = {
  ServiceListing,
  ServiceGroupListing,
  getById,
  Create
};

export default Services;
