import React from 'react';
import PropTypes from 'prop-types';

function DayHeader({ day }) {
  return (
    <li>
      <span>
        {day.format('ddd')}
      </span>
      <span className="slot-date">
        {day.format('D MMM')}
        {' '}
        <small className="slot-year">{day.format('YYYY')}</small>
      </span>
    </li>


  );
}
DayHeader.propTypes = {
  day: PropTypes.object.isRequired

};
export default DayHeader;
