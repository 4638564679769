import React, { useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import PatientServices from 'src/services/api/patient';
import BookingServices from 'src/services/api/booking';
import moment from 'moment';
import { useStateMachine } from 'little-state-machine';
import updateAction from 'src/components/updateAction';
import { useUser } from 'reactfire';
import { useForm, FormProvider } from 'react-hook-form';

import { v4 } from 'uuid';
import LocationSearchInput from './LocationSearchInput';


const titleOptions = [
  'Mr',
  'Mrs',
  'Miss',
  'Dr',
  'Ms',
  'Prof',
  'Rev',
  'Lady',
  'Sir',
  'Capt',
  'Major',
];


function PatientForm(props) {
  const { patient, setPatient, afterSubmit } = props;
  const { action, state } = useStateMachine(updateAction);
  const location = useLocation();
  const user = useUser();
  const [userLocation, setUserLocation] = useState({});

  // Stripe stuff
  const stripe = useStripe();
  const elements = useElements();

  // Stripe States
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);


  const options = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const checkIsChargeable = () => {
    switch (state.data.service.chargeType) {
      case 'NoCharge':
        return false;
      case 'Paid':
        return true;
      case 'FutureInvoice':
        return false;
      default:
        return true;
    }
  };

  const formatLocation = (loc) => {
    console.log(loc);
    if (!loc) {
      return null;
    }
    return `${loc.line1}, ${loc.locality} ${loc.administrativeArea}, ${loc.countryCode}`;
  };

  // setup the form with initial data from the practitioner
  // also make use of formContext for nested components
  let dob;
  if (patient.dateOfBirth) {
    dob = moment(patient.dateOfBirth).format('DD/MM/YYYY');
  }
  const methods = useForm({
    defaultValues: {
      ...patient,
      email: user?.email,
      dateOfBirth: dob,
      locationField: patient?.location?.formattedAddress || '',
    },
  });

  const { dirty } = methods.formState;

  React.useEffect(() => {
    // bit hacky, init the datepicker using external bootstrap libs
    if (window.$('.datetimepicker').length > 0) {
      window.$('.datetimepicker').datetimepicker({
        format: 'DD/MM/YYYY',
        icons: {
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          next: 'fas fa-chevron-right',
          previous: 'fas fa-chevron-left',
        },
      });
    }
  });

  const bookingStatus = {
    REQUESTED: 'Requested',
    CANCELLED: 'Cancelled',
    DECLINED: 'Declined',
    CONFIRMED: 'Confirmed',
    COMPLETED: 'Completed',
    PATIENTTRUANT: 'PatientTruant',
    PRACTITIONERTRUANT: 'PractitionerTruant'
  };

  // create a promise that resolves after a short delay
  function delay(t) {
    return new Promise((resolve) => {
      setTimeout(resolve, t);
    });
  }

  // interval is how often to poll
  // timeout is how long to poll waiting for a result (0 means try forever)
  // url is the URL to request
  function pollUntilDone(interval, timeout, { ...props }) {
    console.log(props);
    const start = Date.now();
    function run() {
      return PatientServices.getBooking(props.authToken, props.patientId, props.bookingId).then((response) => {
        console.log(response);
        if (response.bookingStatus === bookingStatus.CONFIRMED) {
        // we know we're done here, return from here whatever you
        // want the final resolved value of the promise to be
          return response;
        }
        if (timeout !== 0 && Date.now() - start > timeout) {
          throw new Error('timeout error on pollUntilDone');
        } else {
        // run again with a short delay
          return delay(interval).then(run);
        }
      });
    }
    return run();
  }

  // handle the form submit
  const onSubmit = async (values) => {
    setProcessing(true);
    const token = await user.getIdToken();


    const patientId = patient?.id || undefined;
    const version = patient?.version || undefined;

    const doBooking = async (paymentId) => {
      // build the data out to complete the booking
      const bookingId = v4();
      const data = {

        appointmentPatientId: patient.id,
        bookingPatientId: patient.id,
        id: bookingId,
        paymentId,
        practitionerId: state.data.practitioner.id,
        serviceId: state.data.slot.serviceId,
        startTime: state.data.slot.startTime,
        // startTime: moment.utc(state.slot.startTime).format('YYYY-MM-DDTHH:mm:ss'

      };

      if (checkIsChargeable() && !paymentId) {
        throw Error('No Payment id provided for booking');
      }

      try {
        const token = await user.getIdToken();
        const response = await BookingServices.create(token, data);

        // poll the booking status until we have a result
        // polls every 500ms for up to 30 seconds
        pollUntilDone(1000, 60 * 1000, { authToken: token, patientId: patient.id, bookingId: response.id })
          .then((res) => {
            // have final result here
            console.log(res);
            // setBookingState({ isBooking: true, status: res.bookingStatus });
          })
          .catch((err) => {
            // handle error here
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };


    const mylocation = {
      id: v4(),
      line1: `${userLocation.streetNumber} ${userLocation.route}`,
      locality: userLocation.locality,
      administrativeArea: userLocation.administrativeAreaLevel1,
      subAdministrativeArea: userLocation.subAdministrativeArea,
      countryCode: userLocation.country,
      postalCode: userLocation.postalCode,
      placeId: userLocation.placeId,
      formattedAddress: userLocation.formattedAddress

    };

    // fix up the dob field to be "-"" seperated
    let myValues;
    try {
      const parsed = moment(values.dateOfBirth, 'DD/MM/YYYY');
      myValues = {
        ...values,
        location: mylocation,
        dateOfBirth: moment(parsed).format('YYYY-MM-DD'),
      };
    } catch {
      console.log('Couldnt convert date to masked format');
      return;
    }

    // update the patient demographics
    PatientServices.createOrUpdate(token, myValues, patientId, version).then(
      (response) => {
        setPatient(response);
      }
    );


    // Do payment if required
    if (!checkIsChargeable()) {
      // make the booking against the api
      // DoBooking();
      await doBooking();
      props.afterSubmit();
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // call stripe to get payment method id
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });
    if (error) {
      setError(`Payment failed: ${error.message}`);
      setProcessing(false);
      return;
    }


    try {
      // send payment method id to SD API top process paymt
      const token = await user.getIdToken();
      const result = await PatientServices.confirmPayment(
        token,
        patient.id,
        state.data.service.id,
        paymentMethod.id
      );

      setError(null);
      setSucceeded(true);
      setProcessing(false);
      // move on to the confirmation page
      await doBooking(result.paymentId);
      props.afterSubmit();
    } catch (err) {
      setError(`Payment failed: ${err.data.detail}`);
      setProcessing(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {}
        <div className="info-widget">
          <h4 className="card-title">Personal Information</h4>
          <div className="row">
            <div className="col-md-2 col-sm-12">
              <div className="form-group">
                <label>Title</label>
                <select
                  className="form-control"
                  type="text"
                  id="exampleFormControlSelect1"
                >
                  {titleOptions.map((title, i) => (
                    <option key={i}>{title}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="form-group">
                <label>First Name</label>
                <input
                  ref={methods.register({
                    required: {
                      value: true,
                      message: 'First name is required',
                    },
                  })}
                  name="firstName"
                  className="form-control"
                  id="firstName"
                  type="text"
                />
                {methods.errors.firstName && methods.errors.firstName.message}
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  ref={methods.register({
                    required: { value: true, message: 'Last name is required' },
                  })}
                  name="lastName"
                  className="form-control"
                  id="lastName"
                  type="text"
                />
                {methods.errors.lastName && methods.errors.lastName.message}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <fieldset disabled>
                  <label>Email</label>
                  <input
                    name="email"
                    className="form-control"
                    type="email"
                    ref={methods.register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                  {methods.errors.email && methods.errors.email.message}
                </fieldset>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="form-group">
                <label>Date of Birth</label>
                <div className="cal-icon">
                  <input
                    ref={methods.register({
                      required: { value: true, message: 'DOB is required' },
                    })}
                    type="text"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    className="form-control datetimepicker"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="form-group">
                <label>Phone</label>
                <input
                  name="phone"
                  className="form-control"
                  type="text"
                  ref={methods.register({
                    required: {
                      value: true,
                      message: 'Please provide a phone number',
                    },
                  })}
                />
                {methods.errors.phone && methods.errors.phone.message}
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label>Address</label>

                <LocationSearchInput
                  userLocation={userLocation}
                  setUserLocation={setUserLocation}
                />
              </div>
            </div>
            <div className="col-md-10 col-sm-12">
              <div className="form-group">
                <label>Medicare No.</label>
                <input
                  name="medicareNumber"
                  className="form-control"
                  type="text"
                  ref={methods.register({
                    required: {
                      value: true,
                      message: 'Please provide a medicare number',
                    },
                  })}
                />
                {methods.errors.medicareNumber && methods.errors.medicareNumber.message}
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <div className="form-group">
                <label>IRN</label>
                <input
                  name="medicareIrn"
                  className="form-control"
                  type="text"
                  ref={methods.register({
                    required: {
                      value: true,
                      message: 'Please provide a valid IRN',
                    },
                  })}
                />
                {methods.errors.email && methods.errors.email.message}
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label>Pension/Concession Card (Optional)</label>
                <input
                  name="pensionCard"
                  className="form-control"
                  type="email"
                  ref={methods.register()}
                />
                {methods.errors.email && methods.errors.email.message}
              </div>
            </div>
          </div>
        </div>
        {}

        {}
        {checkIsChargeable()
                  && (


                  <div className="payment-widget">
                    <h4 className="card-title">Payment Method</h4>
                    {}
                    <div className="payment-list">
                      <label className="payment-radio credit-card-option">
                        <input type="radio" name="radio" defaultChecked />
                        <span className="checkmark" />
                        Credit card
                      </label>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group card-label">
                            <label htmlFor="card_name">Name on Card</label>
                            <input
                              className="form-control"
                              id="card_name"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group card-label">
                            <label htmlFor="card_name">Card Information</label>

                            <CardElement
                              className="form-control"
                              options={options}
                            />
                            {error && <span className="error text-danger">{error}</span>}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>


                  )}

        <div className="terms-accept">
          <div className="custom-checkbox">
            <input type="checkbox" id="terms_accept" />
            <label htmlFor="terms_accept">
              I have read and accept
              {' '}
              <a href="https://www.swiftdoc.com/SwiftDoc-terms-of-use.pdf">Terms & Conditions</a>
            </label>
          </div>
        </div>
        {}
        {}
        <div className="submit-section mt-4">
          <button type="submit" disabled={processing || !stripe} className="btn btn-primary submit-btn">
            {processing ? 'Processing…' : 'Confirm'}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export default PatientForm;
