// services/api/services.js

import { v4 } from 'uuid';
import request from 'src/shared/lib/request';

function get(id, authToken) {
  return request({
    url: `/patient/${id}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}

// Create or Update a Patient
function createOrUpdate(authToken, values, patientId, version) {
  console.log(values);
  // if we have an id we have a profile to update
  let url = '/patient';
  let method = 'POST';
  if (patientId) {
    url = `/patient/${patientId}`;
    method = 'PUT';
  }

  const data = {
    ...values,
    // values that change between post and put
    version: version || undefined,
    id: patientId || v4(),
  };

  /*
  const data = {
    // values that change between post and put
    version: version || undefined,
    id: patientId || v4(),
    phone: values.phone,
    dateOfBirth: values.dateOfBirth,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    location: {
      administrativeArea: values.locationadministrativeArea,
      countryCode: values.countryCode,
      geoPoint: {
        latitute: 0,
        longitue: 0
      },
      line1: values.line1,
      line2: values.line2,
      line3: values.line3,
      locality: values.locality,
      postalCode: values.postalCode,
      subAdministrativeArea: values.subAdministrativeArea
    },
    medicareIrn: values.medicareIrn,
    medicareNumber: values.medicareNumber
  }; */

  return request({
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    data
  });
}

// Update an existing patient profile
function update(values, id, authToken) {
  console.log(values);

  const uri = `/patient/${id}`;

  return request({
    url: uri,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    data: {
      dateOfBirth: values.dob,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      location: {
        administrativeArea: values.state,
        countryCode: 'AU',
        geoPoint: {
          latitute: 0,
          longitue: 0
        },
        line1: values.address1,
        line2: values.address2,
        line3: 'string',
        locality: values.city,
        postalCode: values.postalCode,
        subAdministrativeArea: 'string'
      },
      medicareIrn: 0,
      medicareNumber: 'string',
      id,
      version: values.version
    }
  });
}

// create a patient profile
function create(values, authToken) {
  return request({
    url: '/patient',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    data: {
      dateOfBirth: values.dob,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      location: {
        administrativeArea: values.state,
        countryCode: 'AU',
        geoPoint: {
          latitute: 0,
          longitue: 0
        },
        line1: values.address1,
        line2: values.address2,
        line3: 'string',
        locality: values.city,
        postalCode: values.postalCode,
        subAdministrativeArea: 'string'
      },
      medicareIrn: 0,
      medicareNumber: 'string',
      id: v4(),
      version: 1
    }
  });
}

function confirmPayment(
  authToken,
  patientId,
  serviceId,
  paymentMethodId,
  paymentIntentId
) {
  const paymentId = v4();

  return request({
    url: `/patient/${patientId}/payment/${paymentId}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'POST',
    data: {
      currency: 'AUD',
      paymentId,
      paymentIntentId,
      paymentMethodId,
      serviceId,
      usingStripeSDK: true
    }
  });
}

function getBooking(authToken, patientId, bookingId) {
  return request({
    url: `/patient/${patientId}/booking/${bookingId}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}

const PatientServices = {
  confirmPayment,
  get,
  getBooking,
  createOrUpdate,
  update,
  create // , update, delete, etc. ...
};

export default PatientServices;
