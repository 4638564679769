import React, { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import {
  withRouter,
} from 'react-router-dom';
import { useAnalytics } from 'reactfire';
import moment from 'moment';
import updateAction from 'src/components/updateAction';

const Result = () => {
  const { action, state } = useStateMachine(updateAction);
  const anayltics = useAnalytics();


  useEffect(() => {
    anayltics.logEvent('booking_complete', { value: state.data.service.priceMicros / 100 });
  }, [anayltics, state.data.service.priceMicros]);

  const startTime = moment(state.data.slot.startTime, 'YYYY-MM-DDTHH:mm:ss').local();
  const endTime = moment(state.data.slot.endTime, 'YYYY-MM-DDTHH:mm:ss').local();


  return (
    <div className="content success-page-cont">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            {}
            <div className="card success-card">
              <div className="card-body">
                <div className="success-cont">
                  <i className="fas fa-check" />
                  <h3>Appointment booked Successfully!</h3>
                  <p>
                    Appointment booked with
                    {' '}
                    <strong>{`${state.data.practitioner.salutation} ${state.data.practitioner.firstName} ${state.data.practitioner.lastName}`}</strong>
                    <br />
                    {' '}
                    on
                    {' '}
                    <strong>
                      {`${moment(startTime).format('DD MMM YYYY hh:mmA')} to ${moment(endTime).format('hh:mmA')}`}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            {}
          </div>
        </div>
      </div>
    </div>
  );
};


export default withRouter(Result);
