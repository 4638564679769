import React from 'react';
import LogoIcon from 'src/components/svg/LogoIcon';
// TODO: SVG logoicon needs looking at, must be a better way to do this
import { AuthCheck, SuspenseWithPerf } from 'reactfire';
import UserMenu from './UserMenu';

const LoginButton = () => (

  <ul className="nav header-navbar-rht">
    <li className="nav-item">

      <a className="nav-link header-login" href="/login">
        login / Signup
        {' '}
      </a>
    </li>
  </ul>
);


const Header = () => (
  <header className="header">
    <nav className="navbar navbar-expand-lg header-nav">
      <div className="navbar-header">
        <a id="mobile_btn" href="javascript:void(0);">
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </a>
        <a href="/" className="navbar-brand logo">

          <LogoIcon className="img-fluid" alt="logo" />
        </a>
      </div>
      <div className="main-menu-wrapper">
        <div className="menu-header">
          <a href="/" className="menu-logo">
            <LogoIcon className="img-fluid" alt="logo" />

          </a>
        </div>

        <AuthCheck fallback={<LoginButton />}>
          <UserMenu />
        </AuthCheck>

      </div>

    </nav>
  </header>
);


export default Header;
