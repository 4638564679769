// services/api/profiles.js

import request from 'src/shared/lib/request';

function get(authToken) {
  console.log(authToken);
  return request({
    url: '/profiles/',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}


function getUserIdForPractitioner(authToken, id) {
  return request({
    url: `/profiles/practitioner/${id}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}

// Get the profile by the user id
function getByUid(authToken, id) {
  return request({
    url: `/profiles/${id}`,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    method: 'GET'
  });
}

// regsiterFCMToken
function registerFCMToken(authToken, values) {
  // if we have an id we have a profile to update
  const url = '/profiles/notifications';
  const method = 'POST';

  const data = {
    ...values,
  };

  return request({
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    data
  });
}


const ProfileServices = {
  get,
  getUserIdForPractitioner,
  registerFCMToken,
  getByUid
  // create //, update, delete, etc. ...
};

export default ProfileServices;
