import React from 'react';
import PropTypes from 'prop-types';


function TimeSlots({ myRef, slot, onClick }) {
  return (
    <div ref={myRef} onClick={onClick} className={slot.isSelected ? 'timing selected' : 'timing'}>
      <span>
        {slot.startTime.format('h:mm').toString()}
      </span>
      {' '}
      <span>{slot.startTime.format('A').toString()}</span>
    </div>
  );
}
TimeSlots.propTypes = {
  slot: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};


export default TimeSlots;
{ /* <TimeSlots myRef={(el) => inputRef.current[i] = el} onClick={() => handleSlotClick(day.format('YYYY-MM-DD').toString(), i)} slot={slot} />} */ }
