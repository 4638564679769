import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'reactfire';
import { Link } from 'react-router-dom';
import PatientServices from 'src/services/api/patient';
import ProfileServices from 'src/services/api/profiles';

// convert string to title case i.e. Firstname Lastname
function titleCase(str) {
  const res = str.toLowerCase().split(' ');
  for (let i = 0; i < res.length; i++) {
    res[i] = res[i].charAt(0).toUpperCase() + res[i].slice(1);
  }
  return res.join(' ');
}

function Register() {
  const defaultAuth = useAuth();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const {
    register, handleSubmit, errors
  } = useForm();

  // handle the form submit
  const onSubmit = async (values) => {
    setError(null);
    setProcessing(true);
    try {
      const response = await defaultAuth.createUserWithEmailAndPassword(
        values.email,
        values.password
      );
      // update the profile
      await response.user.updateProfile({ displayName: titleCase(values.fullName) });

      // Create a patient profile very hacky
      // TODO: when there is an API endpoint for creating a blank profile change it out
      const token = await response.user.getIdToken();
      const fcmTokenHack = {
        deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        deviceType: 'Android',
        fcmToken: '0xDEADBEFF',
        voipToken: '0xDEADBEFF',
      };
      await ProfileServices.registerFCMToken(token, fcmTokenHack);

      setProcessing(false);
    } catch (err) {
      setError(`Signup Failed: ${err.message}`);
      setProcessing(false);
    }
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {}
            <div className="account-content">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12 col-lg-6 login-right">
                  <div className="login-header">
                    <h3>
                      Patient Register
                      {' '}
                      <a href="doctor-register.html">Are you a Doctor?</a>
                    </h3>
                  </div>
                  {}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {error
                    && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                    )}
                    <div className="form-group form-focus">
                      <label className="focus-label">Name</label>

                      <input
                        id="fullName"
                        ref={register({
                          required: {
                            value: true,
                            message: 'Name is required',
                          },
                        })}
                        name="fullName"
                        className="form-control floating"
                        type="text"
                      />

                      {errors.name && errors.name.message}

                    </div>
                    <div className="form-group form-focus">

                      <label className="focus-label">Email</label>
                      <input
                        name="email"
                        className="form-control floating"
                        type="text"
                        placeholder="Email"
                        ref={register({
                          required: {
                            value: true,
                            message: 'Email is required'
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'invalid email address',
                          },
                        })}
                      />
                      {errors.email && errors.email.message}

                    </div>
                    <div className="form-group form-focus">
                      <label className="focus-label">Create Password</label>
                      <input
                        name="password"
                        className="form-control floating"
                        type="password"
                        ref={register({
                          required: {
                            value: true,
                            message: 'Password is required'
                          }
                        })}
                      />
                      {errors.password && errors.password.message}


                    </div>
                    <div className="text-right">
                      <Link className="forgot-link" to="/login">
                        Already have an account?
                      </Link>
                    </div>
                    <button
                      className="btn btn-primary btn-block btn-lg login-btn"
                      type="submit"
                      disabled={processing}
                    >
                      {processing ? 'Processing…' : 'Signup'}
                    </button>
                    <div className="login-or">
                      <span className="or-line" />
                      <span className="span-or">or</span>
                    </div>
                    <div className="row form-row social-login">
                      <div className="col-6">
                        <a href="#" className="btn btn-facebook btn-block">
                          <i className="fab fa-facebook-f mr-1" />
                          {' '}
                          Login
                        </a>
                      </div>
                      <div className="col-6">
                        <a href="#" className="btn btn-google btn-block">
                          <i className="fab fa-google mr-1" />
                          {' '}
                          Login
                        </a>
                      </div>
                    </div>
                  </form>
                  {}
                </div>
              </div>
            </div>
            {}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
