import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useForm } from 'react-hook-form';
import { useAuth, useUser, useAnalytics } from 'reactfire';
import {
  Link,
  useHistory,
  useLocation
} from 'react-router-dom';
import ProfileServices from 'src/services/api/profiles';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';


const schema = yup.object().shape({
  email: yup.string().email().required('A valid email address is required'),
  password: yup.string().required('Password is required'),
});


function Login(props) {
  const defaultAuth = useAuth();
  const user = useUser();
  const [processing, setProcessing] = useState(false);
  const [logonError, setLogonError] = useState(null);

  // const [error, setError] = useState(null);

  const {
    register, handleSubmit, errors
  } = useForm({
    resolver: yupResolver(schema)
  });

  const history = useHistory();
  const location = useLocation();
  const anayltics = useAnalytics();

  const { from } = props.location || location.state || { from: { pathname: '/booking/step1' } };

  useEffect(() => {
    if (user) { history.push('/booking/step1'); }
  }, [history, user]);

  // handle the form submit
  const onSubmit = (values) => {
    setProcessing(true);

    // wait to see if login was successful (we don't care about the return
    // value here)
    defaultAuth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        setProcessing(false);
        history.replace(from);
        anayltics.logEvent('login', { method: 'email_password' });
      })
      .catch((err) => {
        setLogonError(err);
        setProcessing(false);
      });
  };

  const regToken = async (user) => {
    // Create a patient profile very hacky
    // TODO: when there is an API endpoint for creating a blank profile change it out

    const token = await user.getIdToken();
    const fcmTokenHack = {
      deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceType: 'Android',
      fcmToken: '0xDEADBEFF',
      voipToken: '0xDEADBEFF',
    };
    await ProfileServices.registerFCMToken(token, fcmTokenHack);
  };


  const signInWithFacebook = () => {
    // Creates the provider object.
    const provider = new firebase.auth.FacebookAuthProvider();
    // You can add additional scopes to the provider:
    provider.addScope('email');
    // provider.addScope('user_friends');
    // Sign in with popup:
    defaultAuth.signInWithPopup(provider).then((result) => {
      // The firebase.User instance:
      const { user: faceBookUser } = result;
      regToken(faceBookUser);
      // The Facebook firebase.auth.AuthCredential containing the Facebook
      // access token:
      const { credential } = result;
      anayltics.logEvent('login', { method: 'facebook' });
    }, (error) => {
      // The provider's account email, can be used in case of
      // auth/account-exists-with-different-credential to fetch the providers
      // linked to the email:
      const { email } = error;
      // The provider's credential:
      const { credential } = error;
      // In case of auth/account-exists-with-different-credential error,
      // you can fetch the providers using this:
      if (error.code === 'auth/account-exists-with-different-credential') {
        defaultAuth.fetchSignInMethodsForEmail(email).then((providers) => {
          // The returned 'providers' is a list of the available providers
          // linked to the email address. Please refer to the guide for a more
          // complete explanation on how to recover from this error.
        });
      }
    });
  };


  const signInWithGoogle = () => {
    // Creates the provider object.
    const provider = new firebase.auth.GoogleAuthProvider();
    // You can add additional scopes to the provider:
    provider.addScope('email');
    // Sign in with popup:
    defaultAuth.signInWithPopup(provider).then((result) => {
      // The firebase.User instance:
      const { user: googleUser } = result;
      regToken(googleUser);
      // The Facebook firebase.auth.AuthCredential containing the Facebook
      // access token:
      const { credential } = result;
      anayltics.logEvent('login', { method: 'google' });
    }, (error) => {
      // The provider's account email, can be used in case of
      // auth/account-exists-with-different-credential to fetch the providers
      // linked to the email:
      const { email } = error;
      // The provider's credential:
      const { credential } = error;
      // In case of auth/account-exists-with-different-credential error,
      // you can fetch the providers using this:
      if (error.code === 'auth/account-exists-with-different-credential') {
        defaultAuth.fetchSignInMethodsForEmail(email).then((providers) => {
          // The returned 'providers' is a list of the available providers
          // linked to the email address. Please refer to the guide for a more
          // complete explanation on how to recover from this error.
        });
      }
    });
  };

  return (
    <div className="content">

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {}
            <div className="account-content">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12 col-lg-6 login-right">
                  <div className="login-header">
                    <h3>
                      Login SwiftDoc
                    </h3>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {logonError && (
                    <div className="alert alert-danger alert-dismissible fade show">
                      {logonError.message}
                      <button type="button" className="close" data-dismiss="alert">&times;</button>
                    </div>
                    )}
                    <div className="form-group">
                      <label className="focus-label">Email</label>
                      <input
                        ref={register}
                        name="email"
                        type="text"
                        autoComplete="username"
                        className="form-control floating"
                      />

                      {errors.email && (
                      <span style={{ display: errors.email ? 'block' : 'none' }} className="error text-danger">
                        {errors.email.message}
                      </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="focus-label">Password</label>
                      <input
                        ref={register}
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        className="form-control floating"
                      />

                      {errors.password && (
                      <span style={{ display: errors.password ? 'block' : 'none' }} className="error text-danger">
                        {errors.password.message}
                      </span>
                      )}


                    </div>
                    <div className="text-right">
                      <Link className="forgot-link" to="/forgot">
                        Forgot Password ?
                      </Link>
                    </div>
                    <button
                      className="btn btn-primary btn-block btn-lg login-btn"
                      disabled={processing}

                      type="submit"
                    >
                      {processing ? 'Logging in…' : 'Login'}
                    </button>
                    <div className="login-or">
                      <span className="or-line" />
                      <span className="span-or">or</span>
                    </div>
                    <div className="row form-row social-login">
                      <div className="col-6">
                        <button type="button" onClick={() => signInWithFacebook()} className="btn btn-facebook btn-block">
                          <i className="fab fa-facebook-f mr-1" />
                          Login
                        </button>
                      </div>
                      <div className="col-6">
                        <a href="#" onClick={() => signInWithGoogle()} className="btn btn-google btn-block">
                          <i className="fab fa-google mr-1" />
                          Login
                        </a>
                      </div>
                    </div>
                    <div className="text-center dont-have">
                      Don’t have an account?
                      <Link to="/register">Register</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
